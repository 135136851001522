import React from 'react';

import './styles.scss';

export const TBA = () => (
  <div className="tba">
    <div className="tba__message">
      TBA
    </div>
  </div>
);
